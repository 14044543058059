import { calciteTypeToChartDataType } from "../chart/hql/chartHqlUtils.js";
import { ChartDataType } from "../chart/types.js";

import { ExploreField, ExploreFieldType } from "./types.js";

/**
 * A field is a aggregated if (1) it is a measure or (2) it is a
 * dimension/column that has an aggregation set
 */
export function isAggregatedField(field: ExploreField): boolean {
  if (field.fieldType === ExploreFieldType.MEASURE) {
    return true;
  }
  return field.aggregation != null;
}

export function getFieldActiveScaleType(field: ExploreField): ChartDataType {
  return field.scaleType ?? calciteTypeToChartDataType(field.dataType);
}

export const COUNT_STAR_ARG = "_HEX_COUNT_STAR_ARG_";

/**
 * Given an explore field, generates a unique column idenitfier for the field,
 * which includes the query path if it exists.
 */
export function generateColumnIdForField(
  field: Pick<ExploreField, "queryPath" | "value">,
): string {
  // the first item in the query path is always the base dataset
  // so having only 1 item in the query path doesn't point to any joins
  if (field.queryPath == null || field.queryPath.length === 0) {
    return field.value;
  }
  return [...field.queryPath, field.value].join(":");
}
