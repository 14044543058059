import {
  Array,
  Boolean,
  Literal,
  Number,
  Optional,
  Record,
  Static,
  String,
  Undefined,
  Union,
} from "runtypes";

import {
  ChartAxisGrid,
  ChartAxisTicks,
  ChartColorHex,
  ChartColorMappings,
  ChartColorScheme,
  ChartDataLabels,
  ChartDataTypeLiteral,
  ChartGeneralSettings,
  ChartInterpolate,
  ChartLineStroke,
  ChartNumberAxisScale,
  ChartOpacitiesBySeriesValues,
  ChartSeriesTypeLiteral,
  ChartShape,
  ChartSortOrCustomSort,
} from "../chart/types.js";
import {
  DateColumnDisplayFormat,
  DatetimeColumnDisplayFormat,
  NumberColumnDisplayFormat,
} from "../display-table/columnDisplayFormatTypes.js";
import {
  CalciteType,
  CalciteTypeLiteral,
  HqlAggregationFunctionLiteral,
  HqlTruncUnitLiteral,
} from "../hql/types.js";
import {
  DataSourceColumnId,
  DataSourceTableId,
  SemanticDatasetName,
} from "../idTypeBrands.js";
import { getNormalEnum } from "../runtypeEnums.js";

export const ExploreFieldId = String.withBrand("ExploreFieldId");
export type ExploreFieldId = Static<typeof ExploreFieldId>;

export const ExploreAxisId = String.withBrand("ExploreAxisId");
export type ExploreAxisId = Static<typeof ExploreAxisId>;

export const ExploreSeriesId = String.withBrand("ExploreSeriesId");
export type ExploreSeriesId = Static<typeof ExploreSeriesId>;

export const ChartExploreChannelTopLevel = Union(
  Literal("base-axis"),
  Literal("h-facet"),
  Literal("v-facet"),
);
export type ChartExploreChannelTopLevel = Static<
  typeof ChartExploreChannelTopLevel
>;

export const ChartExploreChannelOnePerSeries = Union(
  Literal("cross-axis"),
  Literal("color"),
  Literal("opacity"),
);
export type ChartExploreChannelOnePerSeries = Static<
  typeof ChartExploreChannelOnePerSeries
>;

export const ChartExploreChannelManyPerSeries = Union(Literal("tooltip"));
export type ChartExploreChannelManyPerSeries = Static<
  typeof ChartExploreChannelManyPerSeries
>;

export const PivotExploreChannel = Union(
  Literal("row"),
  Literal("column"),
  Literal("value"),
);
export type PivotExploreChannel = Static<typeof PivotExploreChannel>;

export const ExploreChannel = Union(
  ...ChartExploreChannelTopLevel.alternatives,
  ...ChartExploreChannelOnePerSeries.alternatives,
  ...ChartExploreChannelManyPerSeries.alternatives,
  ...PivotExploreChannel.alternatives,
);
export type ExploreChannel = Static<typeof ExploreChannel>;

// values modelled here should be chart-specific/non-channel-specific config
// that move with the field, and are not channel-specific
// i.e. they make sense for color and x and y etc but not for the pivot table
export const ExploreAxis = Record({
  scale: Optional(ChartNumberAxisScale),
  position: Optional(
    Union(Literal("top"), Literal("bottom"), Literal("left"), Literal("right")),
  ),
  labelAngle: Optional(Number),
  grid: Optional(ChartAxisGrid),
  ticks: Optional(ChartAxisTicks),
  min: Optional(Number),
  max: Optional(Number),
  zero: Optional(Boolean),
  colorScheme: Optional(ChartColorScheme),
  reverseColorScheme: Optional(Boolean),
});
export type ExploreAxis = Static<typeof ExploreAxis>;

// values modelled in *ChannelConfig are series-wide values which should not
// change if a given field is moved to a different channel

export const ExploreOpacitySource = Union(
  Literal("opacity"), // field data if present else staticValue
  Literal("color"), // opacityBySeriesValues
  Literal("base-axis"), // opacityBySeriesValues
);
export type ExploreOpacitySource = Static<typeof ExploreOpacitySource>;

export const ExploreOpacityChannelConfig = Record({
  source: ExploreOpacitySource,
  staticValue: Optional(Number),

  // resolve ambiguity when source='opacity' but no field is present
  // true means use the staticValue, false means render dropdown in Style tab
  staticMode: Optional(Boolean),
});
export type ExploreOpacityChannelConfig = Static<
  typeof ExploreOpacityChannelConfig
>;

export const ExploreColorSource = Union(
  Literal("color"), // field data if present else staticValue
  Literal("base-axis"), // colorBySeriesValues
);
export type ExploreColorSource = Static<typeof ExploreColorSource>;

export const ExploreColorChannelConfig = Record({
  source: ExploreColorSource,
  staticValue: Optional(ChartColorHex),
});
export type ExploreColorChannelConfig = Static<
  typeof ExploreColorChannelConfig
>;

export const ExploreTextChannelConfig = Record({
  source: Union(
    Literal("cross-axis"), // dataLabels
  ),
  dataLabels: Optional(ChartDataLabels),
  totalDataLabels: Optional(ChartDataLabels),
});
export type ExploreTextChannelConfig = Static<typeof ExploreTextChannelConfig>;

export const ExploreTooltipChannelConfig = Record({
  // resolve legacy UI ambiguity when no tooltip field are present
  // in all cases, if any tooltip fields are present, we force manual mode
  tooltipMode: Union(
    Literal("auto"), // don't show manual picker
    Literal("manual"), // show manual picker even if empty
    Literal("none"), // show tooltips as off
  ),
});
export type ExploreTooltipChannelConfig = Static<
  typeof ExploreTooltipChannelConfig
>;

export const ExploreLineStrokeChannelConfig = Record({
  staticValue: Optional(ChartLineStroke),
});
export type ExploreLineStrokeChannelConfig = Static<
  typeof ExploreLineStrokeChannelConfig
>;

export const ExploreLineWidthChannelConfig = Record({
  staticValue: Optional(Number),
});
export type ExploreLineWidthChannelConfig = Static<
  typeof ExploreLineWidthChannelConfig
>;

export const ExploreRadiusChannelConfig = Record({
  staticValue: Optional(Number),
});
export type ExploreRadiusChannelConfig = Static<
  typeof ExploreRadiusChannelConfig
>;

export const ExplorePointSizeChannelConfig = Record({
  staticValue: Optional(Number),
});
export type ExplorePointSizeChannelConfig = Static<
  typeof ExplorePointSizeChannelConfig
>;

export const ExplorePointShapeChannelConfig = Record({
  staticValue: Optional(ChartShape),
});
export type ExplorePointShapeChannelConfig = Static<
  typeof ExplorePointShapeChannelConfig
>;

export const ExploreBin = Record({
  size: Optional(Number),
  count: Optional(Number),
  useColumn: Optional(Boolean),
});
export type ExploreBin = Static<typeof ExploreBin>;

export const ExploreFacet = Record({
  columns: Optional(Number),
  sharedY: Optional(Boolean),
});
export type ExploreFacet = Static<typeof ExploreFacet>;

export const ExploreFieldTypeLiteral = Union(
  // This is a dimension from a semantic dataset
  Literal("DIMENSION"),
  // This is a measure from a semantic dataset
  Literal("MEASURE"),
  // This is a column on the base warehouse table/dataframe
  Literal("COLUMN"),
);
export type ExploreFieldType = Static<typeof ExploreFieldTypeLiteral>;
export const ExploreFieldType = getNormalEnum(ExploreFieldTypeLiteral);

export const ExploreField = Record({
  id: ExploreFieldId,

  // if undefined, then this field belongs to the base dataframe/table/semantic dataset.
  // if referencing a field from a joined table/dataset, then this should be the path to the table
  // e.g. [ "baseTable", "joinedTable" ]
  queryPath: Array(String).Or(Undefined),
  fieldType: ExploreFieldTypeLiteral,
  value: String,
  //TODO(EXPLORE) multi-series pivot tables?
  seriesId: ExploreSeriesId, //always needed even for the pivot tables
  channel: ExploreChannel,
  dataType: CalciteTypeLiteral,
  title: Optional(String), //TODO(EXPLORE) should this appear in pivot, in the field pills?
  displayFormat: Optional(
    //TODO(EXPLORE) this should be respected everywhere
    Union(
      NumberColumnDisplayFormat,
      DateColumnDisplayFormat,
      DatetimeColumnDisplayFormat,
    ),
  ),

  // config for both charts and pivot tables
  aggregation: Optional(HqlAggregationFunctionLiteral), //TODO(EXPLORE): EXP-1221 this should never be set for fieldType == MEASURE
  truncUnit: Optional(HqlTruncUnitLiteral),
  sort: Optional(ChartSortOrCustomSort), //TODO(EXPLORE) pivot tables should support custom sort
  bin: Optional(ExploreBin), //TODO(EXPLORE) pivot tables should support binning

  // charts-specific config
  scaleType: Optional(ChartDataTypeLiteral),
  axis: Optional(ExploreAxis),
  colorsBySeriesValues: Optional(ChartColorMappings),
  opacitiesBySeriesValues: Optional(ChartOpacitiesBySeriesValues),
});

export type ExploreField = Static<typeof ExploreField>;
// Explore field configuration that can be simply adjusted
// without other major structural changes to the related explore spec
export type ExploreFieldSimpleOptions = Omit<
  ExploreField,
  "id" | "value" | "seriesId" | "channel" | "dataType"
>;

export const ExploreViewType = Union(
  Literal("source-table"),
  Literal("visualization"),
);
export type ExploreViewType = Static<typeof ExploreViewType>;

export const ExploreVisualizationType = Union(
  Literal("pivot-table"),
  Literal("chart"),
);
export type ExploreVisualizationType = Static<typeof ExploreVisualizationType>;

export const ExploreChartSeries = Record({
  id: ExploreSeriesId,
  type: ChartSeriesTypeLiteral,
  name: Optional(String),

  // channels
  color: Optional(ExploreColorChannelConfig),
  opacity: Optional(ExploreOpacityChannelConfig),
  tooltip: Optional(ExploreTooltipChannelConfig),

  // these aren't all channels yet but someday could be
  text: Optional(ExploreTextChannelConfig),
  lineStroke: Optional(ExploreLineStrokeChannelConfig),
  lineWidth: Optional(ExploreLineWidthChannelConfig),
  radius: Optional(ExploreRadiusChannelConfig),
  pointShape: Optional(ExplorePointShapeChannelConfig),
  pointSize: Optional(ExplorePointSizeChannelConfig),

  barGrouped: Optional(Boolean),

  normalize: Optional(Union(Literal("facet"), Literal("base-axis"))),

  // applies to line and area
  linePoint: Optional(Boolean),
  lineShape: Optional(ChartInterpolate),

  // applies to area
  areaLine: Optional(Boolean),

  //applies to scatter
  pointFilled: Optional(Boolean),
});
export type ExploreChartSeries = Static<typeof ExploreChartSeries>;

export const ExploreChartSettings = ChartGeneralSettings.omit(
  "selectionEnabled",
  "tooltip",
);
export type ExploreChartSettings = Static<typeof ExploreChartSettings>;

export const ExploreChartConfig = Record({
  series: Array(ExploreChartSeries),
  seriesGroups: Array(Array(ExploreSeriesId)),
  facet: Optional(ExploreFacet),
  settings: ExploreChartSettings,

  // applies to bar today
  orientation: Optional(Union(Literal("vertical"), Literal("horizontal"))),
});
export type ExploreChartConfig = Static<typeof ExploreChartConfig>;

export const ExploreUserJoinColumn = Record({
  name: String,
  dataType: CalciteTypeLiteral,
  /** Present if the related table maps directly to a warehouse table. If a semantic dataset, this won't be defined  */
  dataSourceColumnId: Optional(DataSourceColumnId),
});
export type ExploreUserJoinColumn = Static<typeof ExploreUserJoinColumn>;

export const ExploreUserJoinWarehouseTableReference = Record({
  type: Literal("warehouse"),
  name: String,
});
export const ExploreUserJoinSemanticTableReference = Record({
  type: Literal("semantic"),
  name: SemanticDatasetName,
});

export const ExploreUserJoinTableReference = Union(
  ExploreUserJoinWarehouseTableReference,
  ExploreUserJoinSemanticTableReference,
);

export const ExploreUserJoin = Record({
  /** Optional name of a join. Needed if there are multiple joins combining the same two tables. */
  joinName: Optional(String),

  /** Names of the tables/datasets to join. */
  sourceTable: ExploreUserJoinTableReference,
  targetTable: ExploreUserJoinTableReference,

  sourceTableJoinColumn: ExploreUserJoinColumn,
  targetTableJoinColumn: ExploreUserJoinColumn,

  relationshipType: Union(Literal("to-one"), Literal("to-many")),
});
export type ExploreUserJoin = Static<typeof ExploreUserJoin>;

export const ExploreUserJoinTable = Record({
  /** Identifier for referencing the table. (TODO: more specific type than String?) */
  name: SemanticDatasetName,
  /**
   * Path in the underlying DWH to the table. Should be quoted appropriately.
   * The display name of table can typically be gotten using our
   * `displayNameFromPathToTable()` utility on this value.
   */
  pathToTable: String,
  dataSourceTableId: DataSourceTableId,
  primaryKeyColumn: ExploreUserJoinColumn,
});
export type ExploreUserJoinTable = Static<typeof ExploreUserJoinTable>;

export const ExploreSpec = Record({
  fields: Array(ExploreField),
  chartConfig: ExploreChartConfig,
  visualizationType: ExploreVisualizationType,
  joins: Optional(Array(ExploreUserJoin)),
  tables: Optional(Array(ExploreUserJoinTable)),
});
export type ExploreSpec = Static<typeof ExploreSpec>;
export interface HqlNextColumn {
  /**
   * column name - named this way for consistency with the `ExploreField`
   */
  value: string;
  /**
   * calc expression - if undefined, then this references a column fron the
   * input. If defined, then this is a column that was added via a calc
   * expression.
   */
  calc?: string;
  fieldType: ExploreFieldType;
  dataType: CalciteType;
  queryPath?: string[];
}
